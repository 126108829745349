<template>
  <div>
    <div class="card-table mb-5" :class="mode ? 'card__tableday' : 'card__tablenight'" v-can="'ProfileController@getStaffCard'">
      <div class="workers-info-title-za">
        <div class="title" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.plastic_card')}}</div>
        <i class="el-icon-plus" @click="cardCreate = true" v-can="'ProfileController@addStaffCard'"></i>
      </div>
      <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="w-100" v-loading="loadCard">
          <tr>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.card_number")}}</strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.date_of_expiry")}} </strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.card_organ")}}</strong>
            </td>

            <td colspan="2">
              <strong class="mr-1 font-bold" :class="mode ? 'content__titleday' : 'content__titlenight'"> {{$t("message.status")}}</strong>
            </td>
          </tr>
          <tr
            v-for="(card, index) in cards"
            :key="'staff_salaries-' + index"
            :class="card.status.code == 1 ? 'active' : ''"
          >
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'"> {{ card.card_number }} </span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ card.expiry_date }}</span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ card.card_authority }}</span>
              </p>
            </td>
            <td>
              <p
                class="text-left font-medium pb-1 pt-0 mb-3 mt-3"
                v-if="card.status"
              >
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ card.status.name }} </span>
              </p>
            </td>
            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffCard(card)"
                  v-can="'ProfileController@updateStaffCard'"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteCard(card)"
                  size="small"
                  v-can="'ProfileController@deleteStaffCard'"
                >
                </el-button>
              </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card-table mb-5" :class="mode ? 'card__tableday' : 'card__tablenight'" v-can="'ProfileController@getStaffSalary'">
      <div class="workers-info-title-za">
        <div class="title" :class="mode ? 'text__day2' : 'text__night2'" >{{$t('message.salary')}}</div>
        <i class="el-icon-plus" @click="openCreateSalary" v-can="'ProfileController@addStaffSalary'"></i>
      </div>
      <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
        <table class="w-100" v-loading="loadSalary">
          <tr>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.type_salary')}}</strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.amount_money')}} </strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.datas')}}</strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.in_hour')}} </strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.currency")}} </strong>
            </td>

            <td colspan="2">
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.status")}}</strong>
            </td>
          </tr>
          <tr
            v-for="(salary, index) in salaries"
            :key="'staff_salaries-' + index"
            :class="salary.status.code == 1 ? 'active' : ''"
          >
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'"> {{ filterType(salary.type) }} </span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.amount | formatNumber }}</span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.date_of_start }}</span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.amount_per_hour }}</span>
              </p>
            </td>
            <td>
              <p
                class="text-left font-medium pb-1 pt-0 mb-3 mt-3"
                v-if="salary.currency"
              >
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.currency.name }}</span>
              </p>
            </td>
            <td>
              <p
                class="text-left font-medium pb-1 pt-0 mb-3 mt-3"
                v-if="salary.status"
              >
                <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.status.name }} </span>
              </p>
            </td>
            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editSalary(salary)"
                  v-can="'ProfileController@updateStaffSalary'"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteSalary(salary)"
                  v-can="'ProfileController@deleteStaffSalary'"
                  size="small"
                >
                </el-button>
              </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- start Modal  -->
    <el-dialog
    :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      :title="$t('message.salary')"
      :visible.sync="create"
      width="60%"
      center
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.type_salary')}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="type">
                <el-select
                  class="w-100"
                  v-model="form.type"
                  :id="null"
                  :placeholder="$t('message.type_salary')"
                  size="medium"
                  filterable
                >
                  <el-option
                    v-for="(type, index) in types"
                    :key="'type-' + index"
                    :label="type.label"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t("message.currency")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="currency_id">
                <select-currency
                  v-model="form.currency_id"

                  size="medium"
                ></select-currency>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.official_amount')}}  ({{form.type?$t('message.'+form.type):''}})</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="official_amount">
                <crm-input
                  :placeholder="$t('message.official_amount')"
                  v-model="form.official_amount"
                 
                  :type="'number'"
                  size="medium"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                {{$t('message.unofficial_amount')}}  ({{form.type?$t('message.'+form.type):''}})
              </span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="unofficial_amount">
                <crm-input
                  :placeholder="$t('message.unofficial_amount')"
                  v-model="form.unofficial_amount"
              
                  :type="'number'"
                  size="medium"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12" >
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">  {{$t("message.income_tax")}} %</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="income_tax">
                <crm-input
                  :placeholder="$t('message.income_tax')+' %'"
                  v-model="form.income_tax"
                  :type="'number'"
                  size="medium"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.status")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="status_id">
                <select-status
                  :table_name="'salaries'"
                
                  v-model="form.status_id"
                  size="medium"
                >
                </select-status>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.datas")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="date_of_start">
                <crm-date-picker
               
                  placeholder="Дата начала"
                  v-model="form.date_of_start"
                  size="medium"
                ></crm-date-picker>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="medium"
          @click="submit()"
          
          v-loading="loadForm"
        >
           {{$t("message.save")}}
        </el-button>
        <el-button type="warning" size="medium" @click="close()" >
          {{$t("message.close")}}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
    :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      :title="$t('message.salary')"
      :visible.sync="updateSalary"
      width="60%"
      center
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.type_salary')}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="type">
                <el-select
                  class="w-100"
                  v-model="form.type"
                  :placeholder="$t('message.type_salary')"
                  size="medium"
                  filterable
                >
                  <el-option
                    v-for="(type, index) in types"
                    :key="'type-' + index"
                    :label="type.label"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t("message.currency")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="currency_id">
                <select-currency
                  v-model="form.currency_id"
                  :id="form.currency_id"
                  size="medium"
                ></select-currency>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.official_amount')}}  ({{form.type?$t('message.'+form.type):''}})</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="official_amount">
                <crm-input
                  :placeholder="$t('message.official_amount')"
                  v-model="form.official_amount"
                  :inputValue="form.official_amount"
                  :type="'number'"
                  size="medium"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                {{$t('message.unofficial_amount')}} ({{form.type?$t('message.'+form.type):''}})
              </span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="unofficial_amount">
                <crm-input
                  :placeholder="$t('message.unofficial_amount')"
                  v-model="form.unofficial_amount"
                  :inputValue="form.unofficial_amount"
                  :type="'number'"
                  size="medium"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12" >
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">  {{$t("message.income_tax")}} %</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="income_tax">
                <crm-input
                  :placeholder="$t('message.income_tax')+' %'"
                  v-model="form.income_tax"
                  :type="'number'"
                  size="medium"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.status")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="status_id">
                <select-status
                  :table_name="'salaries'"
                  :id="form.status_id"
                  v-model="form.status_id"
                  size="medium"
                >
                </select-status>
              </el-form-item>
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.datas")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="date_of_start">
                <crm-date-picker
                  :date="form.date_of_start"
                  :placeholder="$t('message.datas')"
                  v-model="form.date_of_start"
                  size="medium"
                ></crm-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="medium"
          @click="update()"
          
          v-loading="loadForm"
        >
           {{$t("message.save")}}
        </el-button>
        <el-button type="warning" size="medium" @click="close()" >
          {{$t("message.close")}}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
    :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      :title="$t('message.salary')"
      :visible.sync="cardCreate"
      width="60%"
      center
    >
      <el-form ref="formCard" :model="formCard" :rules="cardRules">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.card_number')}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="card_number">
                <crm-input
                  :maskFormat="'card'"
                  :placeholder="$t('message.card_number')"
                 
                  v-model="formCard.card_number"
                  :size="'medium'"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"
                >{{$t('message.date_of_expiry')}}</span
              >
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="expiry_date">
                <crm-input
                  :maskFormat="'card_expiry_date'"
                  :placeholder="$t('message.date_of_expiry')"
               
                  v-model="formCard.expiry_date"
                  :size="'medium'"
                >
                </crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.card_organ")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="card_authority">
                <crm-input
                  :placeholder="$t('message.card_organ')"
                
                  v-model="formCard.card_authority"
                  :size="'medium'"
                >
                </crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.status")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="status_id">
                <select-status
                 
                  :table_name="'cards'"
                  v-model="formCard.status_id"
                  :size="'medium'"
                ></select-status>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="medium"
          @click="submitCard()"
          
          v-loading="loadForm"
        >
           {{$t("message.save")}}
        </el-button>
        <el-button
          type="warning"
          size="medium"
          @click="cardCreate = false"
          
        >
          {{$t("message.close")}}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
    :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      :title="$t('message.salary')"
      :visible.sync="cardUpdate"
      width="60%"
      center
    >
      <el-form ref="formCard" :model="formCard" :rules="cardRules">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.card_number')}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="card_number">
                <crm-input
                  :maskFormat="'card'"
                  :placeholder="$t('message.card_number')"
                  :inputValue="formCard.card_number"
                  v-model="formCard.card_number"
                  :size="'medium'"
                ></crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"
                >{{$t('message.date_of_expiry')}}</span
              >
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="expiry_date">
                <crm-input
                  :maskFormat="'card_expiry_date'"
                  :placeholder="$t('message.date_of_expiry')"
                  :inputValue="formCard.expiry_date"
                  v-model="formCard.expiry_date"
                  :size="'medium'"
                >
                </crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.card_organ")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="card_authority">
                <crm-input
                  :placeholder="$t('message.card_organ')"
                  :inputValue="formCard.card_authority"
                  v-model="formCard.card_authority"
                  :size="'medium'"
                >
                </crm-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-8">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.status")}}</span>
              <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="status_id">
                <select-status
                  :id="formCard.status_id"
                  :table_name="'cards'"
                  v-model="formCard.status_id"
                  :size="'medium'"
                ></select-status>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="medium"
          @click="updateCard()"
          
          v-loading="loadCard"
        >
           {{$t("message.save")}}
        </el-button>
        <el-button type="warning" size="medium" @click="close()" >
          {{$t("message.close")}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import selectCurrency from "@/components/filters/inventory/select-currency";
import selectStatus from "@/components/filters/inventory/select-status";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      formCard: {},
      create: false,
      updateSalary: false,
      cardCreate: false,
      cardUpdate: false,
      rules: {
        type: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        status_id: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        currency_id: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        unofficial_amount: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        official_amount: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        amount_per_hour: [
          {
            required: false,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        date_of_start: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
      },
      types: [
        { label: "Фиксированная оплата", value: "fixed" },
        { label: "Почасовая оплата", value: "hourly" },
        //{ label: "PIECEWORK", value: "piecework" },
      ],
      cardRules: {
        status_id: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        card_number: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
        expiry_date: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
          },
        ],
      },
      loadForm: false,
      loadSalary: false,
      loadCard: false,
    };
  },
  components: {
    selectCurrency,
  },
  created() {
    this.getStaffSalary();
    this.getStaffCard();
  },
  computed: {
    ...mapGetters({
      salaries: "profile/staff_salaries",
      cards: "profile/staff_cards",
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "profile/addStaffSalary",
      updateStaffSalary: "profile/updateStaffSalary",
      addStaffCard: "profile/addStaffCard",
      get: "profile/getStaffSalary",
      getCard: "profile/getStaffCard",
      deleteStaffSalary: "profile/deleteStaffSalary",
      deleteStaffCard: "profile/deleteStaffCard",
      updateStaffCard: "profile/updateStaffCard",
    }),
    update() {
      this.$refs["form"].validate((valid) => {
        this.form.staff_id = this.$route.params.id;
        if (!this.loadForm && valid && this.form.staff_id) {
          this.loadForm = true;
          this.updateStaffSalary(this.form)
            .then((res) => {
              this.loadForm = false;
              this.$alert(res);
              if (res.status == 201) {
                this.getStaffSalary();
                this.close();
              }
            })
            .catch((err) => {
              this.loadForm = false;
              this.$alert(err);
            });
        }
      });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        this.form.staff_id = this.$route.params.id;
        if (!this.loadForm && valid && this.form.staff_id) {
          this.loadForm = true;
          this.save(this.form)
            .then((res) => {
              this.loadForm = false;
              this.$alert(res);
              if (res.status == 201) {
                this.getStaffSalary();
                this.close();
              }
            })
            .catch((err) => {
              this.loadForm = false;
              this.$alert(err);
            });
        }
      });
    },
    submitCard() {
      this.$refs["formCard"].validate((valid) => {
        this.formCard.staff_id = this.$route.params.id;
        this.formCard.card_number = this.formCard.card_number
          .replace("-", "")
          .replace("-", "")
          .replace("-", "");
        if (!this.loadCard && valid && this.formCard.staff_id) {
          this.loadCard = true;
          this.addStaffCard(this.formCard)
            .then((res) => {
              this.loadCard = false;
              this.$alert(res);
              if (res.status == 201) {
                this.getStaffCard();
                this.close();
              }
            })
            .catch((err) => {
              this.loadCard = false;
              this.$alert(err);
            });
        }
      });
    },
    getStaffSalary() {
      if (this.$route.params.id) {
        if (!this.loadSalary) {
          this.loadSalary = true;
          this.get(this.$route.params.id)
            .then((res) => {
              this.loadSalary = false;
            })
            .catch((err) => {
              this.$alert(err);
              this.loadSalary = false;
            });
        }
      }
    },
    getStaffCard() {
      if (this.$route.params.id) {
        if (!this.loadCard) {
          this.loadCard = true;
          this.getCard(this.$route.params.id)
            .then((res) => {
              this.loadCard = false;
            })
            .catch((err) => {
              this.$alert(err);
              this.loadCard = false;
            });
        }
      }
    },
    editSalary(salary) {
      this.form = JSON.parse(JSON.stringify(salary));
      this.updateSalary = true;
    },
    deleteSalary(salary) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning",
      })
        .then(() => {
          if (!this.loadSalary && this.$route.params.id) {
            this.loadSalary = true;
            let form = {
              staff_id: this.$route.params.id,
              salary_id: salary.id,
            };
            this.deleteStaffSalary(form)
              .then((res) => {
                this.loadSalary = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.getStaffSalary();
                }
              })
              .catch((err) => {
                this.loadSalary = false;
                this.$alert(err);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    deleteCard(card) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning",
      })
        .then(() => {
          if (!this.loadSalary && this.$route.params.id) {
            this.loadSalary = true;
            let form = {
              staff_id: this.$route.params.id,
              card_id: card.id,
            };
            this.deleteStaffCard(form)
              .then((res) => {
                this.loadSalary = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.getStaffCard();
                }
              })
              .catch((err) => {
                this.loadSalary = false;
                this.$alert(err);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    filterType(type) {
      return _.find(this.types, function (o) {
        return o.value == type;
      }).label;
    },
    close() {
      this.clearForm();
      this.create = false;
      this.cardCreate = false;
      this.cardUpdate = false;
      this.updateSalary = false;
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
      for (var key in this.formCard) {
        this.formCard[key] = null;
      }
    },
    updateCard() {
      this.formCard.staff_id = this.$route.params.id;
      this.formCard.card_id = this.formCard.id;
      this.formCard.card_number = this.formCard.card_number
        .replace("-", "")
        .replace("-", "")
        .replace("-", "");
      if (this.formCard.staff_id && this.formCard.card_id) {
        this.loadCard = true;
        this.updateStaffCard(this.formCard)
          .then((res) => {
            this.$alert(res);
            if (res.status == 201) {
              this.loadCard = false;
              this.clearForm();
              this.getStaffCard();
              this.close();
            }
          })
          .catch((err) => {
            this.$alert(err);
            this.loadCard = false;
          });
      }
    },
    editStaffCard(card) {
      this.formCard = JSON.parse(JSON.stringify(card));
      this.cardUpdate = true;
    },
    openCreateSalary() {
      this.create = true;
      this.form = {};
    },
  },
};
</script>

<style lang="scss">
  .ichki_modal{
    background: rgba($color: #000000, $alpha: 0.5);
  }
</style>
