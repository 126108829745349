<template >
<div>
  <div v-loading="loadingData" >
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <div class="large--title m-0 d-flex align-center">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="mr-4"
              >Информация о <b>{{ form.name }} {{ form.last_name }}</b>
            </span>
          </div>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div>
        <div class="app-modal__body p-5 pb-5">
          <el-form ref="form" :model="form" :rules="rules">
            <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
              <el-row :gutter="20" class="mb-5">
                <el-col :span="6" class="pt-3">
                  <div id="app" class="my-upload">
                    <el-upload
                      class="upload-demo"
                      action="/"
                      :limit="1"
                      :on-change="createImageList"
                      :file-list="imageList"
                      list-type="picture-card"
                      :auto-upload="false"
                      :class="mode ? '' : 'upload__mynight'"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <!-- <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog> -->
                    <!-- <div v-if="!image" class="u-file">
                                <label
                                for="file-upload"
                                class="my-button ml-2 colorQr width-auto"
                                >
                                <i class="el-icon-plus"></i>
                                </label>
                                <input
                                id="file-upload"
                                type="file"
                                @change="onFileChange"
                                />
                            </div>
                            <div v-else>
                                <img
                                :src="form.image ? baseUrl + form.image.path : form.image"
                                class="upload-img-task"
                                />
                                <br />
                                <el-button
                                size="mini"
                                @click="removeImage"
                                type="danger"
                                plain
                                class="dalet-img"
                                >
                                Удалить
                                </el-button>
                            </div> -->
                  </div>
                </el-col>
                <el-col :span="18">
                  <el-row :gutter="20" class="mt-5">
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.last_name") }}
                        </span>
                        <el-form-item prop="last_name">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :placeholder="$t('message.last_name')"
                            :inputValue="form.last_name"
                            v-model="form.last_name"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.middle_name") }}
                        </span>
                        <el-form-item prop="middle_name">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :inputValue="form.middle_name"
                            :placeholder="$t('message.middle_name')"
                            v-model="form.middle_name"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.nameFull") }}
                        </span>
                        <el-form-item prop="name">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :placeholder="$t('message.nameFull')"
                            :inputValue="form.name"
                            v-model="form.name"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.date_of_birth") }}
                        </span>
                        <el-form-item prop="date_of_birth">
                          <crm-date-picker
                          :class="mode ? 'input__day' : 'input__night'"
                            :size="'medium'"
                            :placeholder="$t('message.date_of_birth')"
                            :date="form.date_of_birth"
                            v-model="form.date_of_birth"
                          ></crm-date-picker>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                           {{$t("message.country_passport")}}
                          </span>
                          <el-select
                                class="w-100"
                                v-model="form.maskFormat"
                                placeholder="Процесс онбординга "
                                size="medium"
                                @change="changeMask"
                                :class="mode ? 'input__day' : 'input__night'"
                              >
                                <el-option label="Узбекистан" value="pas_uz"></el-option>
                                <el-option label="Турция" value="pas_tr"></el-option>
                                <el-option label="Индия" value="pas_ind"></el-option>
                                <el-option label="Россия" value="pas_ru"></el-option>
                                <el-option label="Казахстан" value="pas_kz"></el-option>
                                <el-option label="Киргизстан" value="pas_kg"></el-option>
                              </el-select>
                          </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.passport_number") }}
                        </span>
                              <el-form-item prop="passport_number">
                                <crm-input
                                :class="mode ? 'input__day' : 'input__night'" 
                                  :placeholder="$t('message.passport_number')"
                                  :maskFormat="form.maskFormat"
                                  :inputValue="form.passport_number"
                                  v-model="form.passport_number"
                                  :size="'medium'"
                                ></crm-input>
                                </el-form-item>
                          
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.passport_issued_by") }}
                        </span>
                        <el-form-item prop="passport_issued_by">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :placeholder="$t('message.passport_issued_by')"
                            :inputValue="form.passport_issued_by"
                            v-model="form.passport_issued_by"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.passport_valid_until") }}
                        </span>
                        <el-form-item prop="passport_valid_until">
                          <crm-date-picker
                          :class="mode ? 'input__day' : 'input__night'"
                            v-model="form.passport_valid_until"
                            :date="form.passport_valid_until"
                            :size="'medium'"
                          >
                          </crm-date-picker>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.email") }}
                        </span>
                        <el-form-item prop="email">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :type="'email'"
                            :placeholder="$t('message.email')"
                            :inputValue="form.email"
                            v-model="form.email"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.phone_number") }}
                        </span>
                        <el-form-item prop="phone_number">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :type="'tel'"
                            :maskFormat="'tel'"
                            :placeholder="$t('message.phone_number')"
                            :inputValue="form.phone_number"
                            v-model="form.phone_number"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.income") }}
                        </span>
                        <el-form-item prop="income_id">
                          <select-income
                            :id="form.income_id"
                            v-model="form.income_id"
                            :size="'medium'"
                          ></select-income>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                          $t("message.gender")
                        }}</span>
                        <el-form-item prop="gender_id">
                          <select-gender
                            :id="form.gender_id"
                            v-model="form.gender_id"
                            :size="'medium'"
                          ></select-gender>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                          $t("message.family_location")
                        }}</span>
                        <el-form-item>
                          <select-family-status
                            :id="form.family_status_id"
                            v-model="form.family_status_id"
                            :size="'medium'"
                           
                          ></select-family-status>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.pinfl") }}
                        </span>
                        <el-form-item prop="pinfl">
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                            :inputValue="form.inn"
                            :placeholder="'9 999999 999 999 9'"
                            v-model="form.inn"
                            :maskFormat="'pinfl'"
                            :size="'medium'"
                          ></crm-input>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                          $t("message.status")
                        }}</span>
                        <el-form-item prop="status_id">
                          <select-status-modal
                            :id="form.status_id"
                            :table_name="'staffs'"
                            v-model="form.status_id"
                            :size="'medium'"
                            @parent-function="onOptionChanged"
                          ></select-status-modal>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.work_type") }}
                        </span>
                        <el-form-item prop="work_type_id">
                          <select-work-type
                            :id="form.work_type_id"
                            v-model="form.work_type_id"
                            :size="'medium'"
                          >
                          </select-work-type>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                          $t("message.nation")
                        }}</span>
                        <el-form-item prop="nation_id">
                          <select-nation
                            :size="'medium'"
                            :placeholder="columns.nation_id.title"
                            :id="form.nation_id"
                            v-model="form.nation_id"
                          >
                          </select-nation>
                        </el-form-item>
                      </div>
                    </el-col>
                     <el-col :span="12">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                          $t("message.staff_work_type")
                        }}</span>
                        <el-form-item prop="staff_work_type_id">
                          <select-staff-work-type
                            :size="'medium'"
                            :placeholder="columns.staff_work_type_id"
                            :id="form.staff_work_type_id"
                            v-model="form.staff_work_type_id"
                          >
                          </select-staff-work-type>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="7">
                      <div class="app-form__group mb-4">
                        <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                            $t("message.region")
                          }}</span>
                          <el-form-item prop="region_id">
                            <select-region
                              :id="form.region_id"
                              v-model="form.region_id"
                              :size="'medium'"
                            >
                            </select-region>
                          </el-form-item>
                        </div>
                      </div>
                  </el-col>
                <el-col :span="7">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.district_id")
                      }}</span>
                      <el-form-item prop="district_id">
                        <select-district
                          :region_id="form.region_id"
                          v-model="form.district_id"
                          :id="form.district_id"
                          :size="'medium'"
                        ></select-district>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                 <el-col :span="10">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.address")
                      }}</span>
                       <el-form-item prop="address">
                        <crm-input
                        :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.address')"
                          v-model="form.address"
                          :inputValue="form.address"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                    
                  </el-row>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="24">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.job") }}</span
                    >
                    <hr />
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.hiring_date") }}
                    </span>
                    <el-form-item prop="hiring_date">
                      <crm-date-picker
                      :class="mode ? 'input__day' : 'input__night'"
                        :date="form.hiring_date"
                        v-model="form.hiring_date"
                        :size="'medium'"
                      >
                      </crm-date-picker>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                      $t("message.employmentType")
                    }}</span>
                    <el-form-item prop="employment_type_id">
                      <select-employment-type
                        :id="form.employment_type_id"
                        v-model="form.employment_type_id"
                        :size="'medium'"
                      ></select-employment-type>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                      $t("message.graphic")
                    }}</span>
                    <el-form-item prop="graphic_id">
                      <select-graphic
                        :id="form.graphic_id"
                        v-model="form.graphic_id"
                        :size="'medium'"
                      ></select-graphic>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="6" v-if="is_free">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{$t("message.limit_work_time")}}</span
                    >
                    <el-form-item prop="monthly_work_hour">
                      <!-- <select-graphic
                        :id="form.monthly_work_hour"
                        v-model="form.monthly_work_hour"
                        :size="'medium'"
                      ></select-graphic> -->
                      <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.limit_work_time')"
                          v-model="form.monthly_work_hour"
                          :inputValue="form.monthly_work_hour"
                          :type="'number'"
                          :size="'medium'"
                          ></crm-input>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.company") }}
                      </span>
                      <el-form-item prop="company_id">
                        <select-company
                          :id="form.company_id"
                          v-model="form.company_id"
                          :size="'medium'"
                        ></select-company>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.branch") }}
                      </span>
                      <el-form-item prop="branch_id">
                        <select-branch
                          :id="form.branch_id"
                          :company_id="form.company_id"
                          v-model="form.branch_id"
                          :size="'medium'"
                        ></select-branch>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.department")
                      }}</span>
                      <el-form-item prop="department_id">
                        <select-department
                          :id="form.department_id"
                          :branch_id="form.branch_id"
                          v-model="form.department_id"
                          :size="'medium'"
                        ></select-department>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.position")
                      }}</span>
                      <el-form-item prop="position_id">
                        <select-position
                          :id="form.position_id"
                          :company_id="form.company_id"
                          v-model="form.position_id"
                          :size="'medium'"
                        ></select-position>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.position")
                      }}</span>
                      <el-form-item prop="position_id">
                        <select-position
                          :company_id="form.company_id"
                          :id="form.position_ids"
                          v-model="form.position_ids"
                          :size="'medium'"
                          :multiple="true"
                        ></select-position>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.category")
                      }}</span>
                      <el-form-item prop="category_id">
                        <select-category
                          :company_id="form.company_id"
                          :id="form.category_id"
                          v-model="form.category_id"
                          :size="'medium'"
                         
                        ></select-category>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
               
              </el-row>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                      $t("message.manager")
                    }}</span>
                    <el-form-item prop="manager_id">
                      <select-staff
                        :excepted_id="form.id"
                        :id="form.manager_id"
                        :placeholder="$t('message.manager')"
                        v-model="form.manager_id"
                        :size="'medium'"
                      >
                      </select-staff>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        Процесс онбординга
                    </span>
                    <el-form-item prop="">
                    <el-select
                        class="w-100"
                        v-model="form.valu2"
                        placeholder=" Процесс онбординга "
                        size="medium"
                    >
                        <el-option label="Zone one" value="shangh"></el-option>
                        <el-option label="Zone two" value="beijing"></el-option>
                    </el-select>
                    </el-form-item>
                    </div>
                </el-col>             
                </el-row> -->
              <!-- <el-row :gutter="20" class="mt-5">
                <el-col :span="5">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.allow_th_employee_to_access_the_system") }}
                    </span>
                    <el-switch v-model="form.allow_to_be_user"> </el-switch>
                  </div>
                </el-col> -->
                <!-- <el-col :span="8" v-if="form.allow_to_be_user">
                  <select-user :size="'medium'" v-model="form.parent_user_id">

                  </select-user>
                </el-col> -->
              <!-- </el-row> -->
              <!-- <div class="card-table mb-5">
                <div class="workers-info-title-za">
                  <div class="title">Пластиковая карта</div>
                  <i class="el-icon-plus" @click="openCreateCard"></i>
                </div>
                <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
                  <table class="w-100" v-loading="loadCard">
                    <tr>
                      <td>
                        <strong class="mr-1 font-bold">Номер карты</strong>
                      </td>
                      <td>
                        <strong class="mr-1 font-bold"> Дата истечения срока </strong>
                      </td>
                      <td>
                        <strong class="mr-1 font-bold">Карточный орган</strong>
                      </td>

                      <td colspan="2">
                        <strong class="mr-1 font-bold"> Статус</strong>
                      </td>
                    </tr>
                    <tr
                      v-for="(card, index) in cards"
                      :key="'staff_salaries-' + index"
                      :class="card.status.code == 1 ? 'active' : ''"
                    >
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'"> {{ card.card_number }} </span>
                        </p>
                      </td>
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ card.expiry_date }}</span>
                        </p>
                      </td>
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ card.card_authority }}</span>
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-left font-medium pb-1 pt-0 mb-3 mt-3"
                          v-if="card.status"
                        >
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ card.status.name }} </span>
                        </p>
                      </td>
                      <td class="text-right">
                        <el-row>
                          <el-button
                            type="primary"
                            icon="el-icon-edit"
                            circle
                            size="small"
                            @click="editStaffCard(card)"
                          ></el-button>
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            @click="deleteCard(card)"
                            size="small"
                          >
                          </el-button>
                        </el-row>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="card-table mb-5">
                <div class="workers-info-title-za">
                  <div class="title">Зарплата</div>
                  <i class="el-icon-plus" @click="openCreateSalary"></i>
                </div>
                <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
                  <table class="w-100" v-loading="loadSalary">
                    <tr>
                      <td>
                        <strong class="mr-1 font-bold">Тип З/П</strong>
                      </td>
                      <td>
                        <strong class="mr-1 font-bold"> Количество денег </strong>
                      </td>
                      <td>
                        <strong class="mr-1 font-bold">Дата</strong>
                      </td>
                      <td>
                        <strong class="mr-1 font-bold">В час </strong>
                      </td>
                      <td>
                        <strong class="mr-1 font-bold">Валюта </strong>
                      </td>

                      <td colspan="2">
                        <strong class="mr-1 font-bold"> Статус</strong>
                      </td>
                    </tr>
                    <tr
                      v-for="(salary, index) in salaries"
                      :key="'staff_salaries-' + index"
                      :class="salary.status.code == 1 ? 'active' : ''"
                    >
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'"> {{ filterType(salary.type) }} </span>
                        </p>
                      </td>
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.amount }}</span>
                        </p>
                      </td>
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.date_of_start }}</span>
                        </p>
                      </td>
                      <td>
                        <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3">
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.amount_per_hour }}</span>
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-left font-medium pb-1 pt-0 mb-3 mt-3"
                          v-if="salary.currency"
                        >
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.currency.name }}</span>
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-left font-medium pb-1 pt-0 mb-3 mt-3"
                          v-if="salary.status"
                        >
                          <span :class="mode ? 'text__day2' : 'text__night2'">{{ salary.status.name }} </span>
                        </p>
                      </td>
                      <td class="text-right">
                        <el-row>
                          <el-button
                            type="primary"
                            icon="el-icon-edit"
                            circle
                            size="small"
                            @click="editSalary(salary)"
                          ></el-button>
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            @click="deleteSalary(salary)"
                            size="small"
                          >
                          </el-button>
                        </el-row>
                      </td>
                    </tr>
                  </table>
                </div>
              </div> -->
              <el-row :gutter="20" class="mt-3">
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.leader") }}
                    </span>
                    <el-switch v-model="form.is_leader"> </el-switch>
                  </div>
                </el-col>
              </el-row>
            <div :class="mode ? '' : 'performance__night p-4 pb-0'">
              <crm-salary></crm-salary>
            </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
 
  <el-dialog 
  v-if="isTrue"
  title="Причина"
  class="ichki_modal"
   :visible.sync="outerVisible"
    width="60%"
    center
    >
     <div>
       <el-row :gutter="20" class="mb-5">
         <el-col :span="12">
           <el-select
             :value="form.reason"
             filterable
             clearable
             class="d-block"
             v-model="form.reason"
             @change="changeReason"
           >
             <el-option
               v-for="(reason, index) in reasons"
               :key="'reason-' + index"
               :label="reason.name"
               :value="reason.id"
             >
           </el-option>
         </el-select>
         </el-col>
         <el-col :span="12">
            <el-date-picker
             v-model="form.reason_date"
             type="date"
             width="100%"
             style="width:100%"
             value-format="yyyy-MM-dd"
             placeholder="Причина дате">
           </el-date-picker>
         </el-col>
         <el-col :span="12" v-if="form.children_count">
          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mt-2 mb-2">New {{$t('message.manager')}}</span>
          <select-staff
            :size="'medium'"
            :id="form.new_manager_id"
            v-model="form.new_manager_id"
          ></select-staff>
        </el-col>
       </el-row>
     </div>
     <div slot="footer" class="dialog-footer">
       <el-button type="primary" @click="outerVisible = false">Ok</el-button>
     </div>
   </el-dialog>
</div>
</template>
<script>
import selectStatus from "@/components/filters/inventory/select-status-modal";
import selectStaffWorkType from "@/components/filters/inventory/select-staff-work-type";
import selectGender from "@/components/filters/inventory/select-gender";
import selectRegion from "@/components/filters/inventory/select-region";
import selectFamilyStatus from "@/components/filters/inventory/select-familyStatus";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import selectDistrict from "@/components/filters/inventory/select-district";
import selectPosition from "@/components/filters/inventory/select-position";
import selectCompany from "@/components/filters/inventory/select-company";
import selectBranch from "@/components/filters/inventory/select-branch";
import selectDeparment from "@/components/filters/inventory/select-department";
import selectEmploymentType from "@/components/filters/inventory/select-employmentType";
import selectIncome from "@/components/filters/inventory/select-income";
import selectWorkType from "@/components/filters/inventory/select-work-type";
import selectStaff from "@/components/filters/inventory/select-staff";
import selectNation from "@/components/filters/inventory/select-nation";
import selectCurrency from "@/components/filters/inventory/select-currency";
import workHistory from '@/views/profile/tabs/components/workHistory'
import CrmSalary from "../../profile/tabs/crm-salary.vue";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import axios from 'axios'
import SelectUser from '../../../components/filters/inventory/select-user.vue';

export default {
  mixins: [drawer],
  name: "StaffController",
  data() {
    return {
      form: {
        maskFormat:'pas_uz',
        candidate_id: null,
        reason:null,
        allow_to_be_user:false,
      },
      is_free:false,
      formSalary:{},
      formCard:{},
      image: "",
      types: [
        { label: "Фиксированная оплата", value: "fixed" },
        { label: "Почасовая оплата", value: "hourly" },
      ],
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      imageList: [],
      loadingData: false,
      loadingButton: false,
      type: "",
      loadForm:false,  
      loadWorkPlaces: false,
      reasons:'',
      outerVisible: false,
      innerVisible: false,
      isTrue: true
     
    };
  },
  props: {
    selectedItem: {
      type: Object,
    },
  },
  mounted(){
    this.getStaffAssentReason();
  },
  components: {
    CrmSalary,
    selectStatus,
    selectGender,
    selectRegion,
    selectFamilyStatus,
    selectDistrict,
    selectEmploymentType,
    selectPosition,
    selectCompany,
    selectBranch,
    selectDeparment,
    selectStaff,
    selectIncome,
    selectWorkType,
    selectNation,
    selectGraphic,
    selectCurrency,
    workHistory,
    selectStaffWorkType,
    SelectUser
  },
  watch: {
    "form.candidate_id": {
      handler: function () {
        this.imageList = [];
        this.fetchCandidate();
      },
    },
    form: {
      handler: function () {},
      immediate: true,
      deep: true,
    },
    "form.company_id": {
      handler: function (newVal, oldVal) {
        this.type = "companies";
      },
    },
    "form.branch_id": {
      handler: function (newVal, oldVal) {
        this.type = "branches";
      },
    },
    "form.department_id": {
      handler: function (newVal, oldVal) {
        this.type = "departments";
      },
    },
    "form.reason": {
      handler: function (newVal, oldVal) {
     
      },
    },
    "form.reason_date": {
      handler: function (newVal, oldVal) {
      },
    },
    "form.staff_work_type_id": {
      handler: function (newVal, oldVal) {
      },
    },
    "form.graphic_id": {
      handler: function (newVal, oldVal) {
        // this.is_free = _.filter(this.staffs, ["department_id", this.department_id])
        if (newVal) {
          this.is_free = this.graphics.find(x => x.id === newVal).is_free;
          console.log(this.is_free);
        }
        // console.log(this.graphics);
      },
      deep: true,
      immediate: true,
    },
     maskFormat: {
      handler: function (e) {
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      rules: "staff/rules",
      model: "staff/model",
      columns: "staff/columns",
      candidate: "candidate/model",
      staffWorkHistory: "profile/staff_work_history",
      graphics: 'graphic/inventory',
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      showCanidate: "candidate/show",
      show: "staff/show",
      save: "staff/update",
      empty: "staff/empty",
      getStaffWorkHistory: "profile/getStaffWorkHistory",
    }),

     fetchStaffWorkHistory() {
      if (!this.loadWorkPlaces && this.$route.params.id) {
        this.loadWorkPlaces = true;
        this.getStaffWorkHistory(this.$route.params.id)
          .then((res) => {
            this.loadWorkPlaces = false;
          })
          .catch((err) => {
            this.loadWorkPlaces = false;
          });
      }
    },
    filterType(type) {
      return _.find(this.types, function (o) {
        return o.value == type;
      }).label;
    },
    afterOpen() {
      this.fetchData();
    },
    fetchData() {
      if (!this.loadingData) {
        this.loadingData = true;
        this.show(this.selectedItem.id)
          .then((res) => {
            this.form = JSON.parse(JSON.stringify(res.data.result.data.staff));
            if (this.form.image) {
              this.imageList.push({
                name: this.form.image.path,
                url: this.baseUrl + this.form.image.path,
              });
            }
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    createImageList(file) {
      this.form.image = file.raw;
    },
    fetchCandidate() {
      if (!this.loadingData && this.form.candidate_id) {
        this.empty();
        this.loadingData = true;
        this.showCanidate(this.form.candidate_id)
          .then((res) => {
            delete this.candidate.id;
            this.form = {
              ...this.form,
              ...JSON.parse(JSON.stringify(this.candidate)),
            };

            if (this.form.image) {
              this.imageList.push({
                name: this.form.image.path,
                url: this.baseUrl + this.form.image.path,
              });
            }
            delete this.form.status_id;
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
      if (!this.loadingData && !this.form.candidate_id) {
        this.empty();
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      this.image = "";
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid && this.type != "") {
          let formData = new FormData();
          if (this.type == "companies") {
            this.form.branch_id = null;
            this.form.department_id = null;
          }
          if (this.type == "branches") {
            this.form.department_id = null;
          }
          for (var propName in this.form) {
            if (
              this.form[propName] === null ||
              this.form[propName] === undefined
            ) {
              delete this.form[propName];
            }
          }

          for (const key in this.form) {
            formData.append(key, this.form[key]);
             if (key == "position_ids") {
              for (const id in this.form[key]) {
                formData.append("position_ids[]", this.form[key][id]);
              }
            }
          }
          if (_.size(this.imageList) > 0) {
            for (const key in this.imageList) {
              if (this.imageList.hasOwnProperty(key)) {
                formData.append(`image`, this.imageList[key]);
              }
            }
          }

          formData["id"] = this.form.id;
          this.save(formData)
            .then((res) => {
              this.parent().listChanged();
              this.$alert(res);
              this.fetchStaffWorkHistory();
              if (res.status == 201 || res.status == 200) {
                this.close();
                this.empty();
                this.imageList = [];
              }
            })
            .catch((err) => {
              this.$alert(err);
            });
        }
      });
    },
    afterLeave() {
      this.fileList = [];
      this.imageList = [];
      this.empty();
    },

    onOptionChanged(e){
      this.getStaffAssentReason();
      this.form.reason = this.reasons[0].id;
      this.outerVisible = true;
    },
     dispatchtwo(e){
      this.selected_two = e;
    },
    getStaffAssentReason() {
     axios.get(process.env.VUE_APP_URL + '/settings/reasons')
          .then(resp => (this.reasons = resp.data.result.data.reasons ))
          .catch(err => console.log(err));
    },
    changeReason(e){
       this.form.reason = e;
    },
     changeMask(val){
      this.form.passport_number = '';
      this.form.maskFormat = val;
    },
   
  },
};
</script>
<style lang="scss" >
.el-drawer__body{
  overflow-y: scroll;
}
.v-modal{
 z-index: 2000 !important;
}
.ichki_modal{
    background: rgba($color: #000000, $alpha: 0.5);
  }
.my-upload {
  .upload-demo {
    position: relative;
  }
  .el-upload-list {
    position: relative;
    z-index: 2;
  }
  .el-upload.el-upload--picture-card {
    position: absolute;
    left: 0px !important;
    z-index: 1;
    width: 95%;
    height: 265px;
    line-height: 262px;
    vertical-align: top;
    // margin-left: 4%;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 95%;
    height: 265px;
    line-height: 262px;
    vertical-align: top;
    // margin-left: 4%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.upload__mynight{
  .el-upload.el-upload--picture-card{
    background-color: transparent;
  }
}
</style>
